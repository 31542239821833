import React, {useState} from 'react'
import TextCard from '~/components/molecules/TextCard'
import ArticlePreview from '~/components/molecules/ArticlePreview'
import Button from '~/components/atoms/Button'
import RadioField from '~/components/atoms/RadioField'
import usePostPreviews from '~/hooks/usePostPreviews';
import { Formik, Form } from 'formik';

export default function Resources({ className, resourceType='' }) {
  const posts = usePostPreviews({
    resourceType
  });

  const [limit, setLimit] = useState(12);
  return (
    <section className={`my-20 md:my-36 ${className}`}>
      <div className="container">
        <header className="flex flex-col md:flex-row md:items-center mb-16 md:mb-20 space-y-8 md:space-y-0">
          <TextCard
            heading={{"text" : "All content", "type": "h2"}}
            headingClassName="md:mb-0"
          />
          <div hidden className="md:ml-auto">
            <Formik
              initialValues={{
                all: true,
                article: '',
              }}>
              {(props) => (
                <Form className="flex space-x-8">
                  <RadioField name="all" label="View all" />
                  <RadioField name="article" label="Article" />
                </Form>
              )}
            </Formik>
          </div>
        </header>
        <div className="grid grid-cols-12 gap-y-16 md:gap-12">
          {posts.slice(0, limit).map((item, i) => (
            <ArticlePreview key={`post${i}`} {...item} className="col-span-12 md:col-span-4" />
          ))}
        </div>
        <div className="flex justify-center mt-20">
          <div onClick={e => setLimit(limit+12)}>
            <Button title="View more" className="min-w-[15em] btn--secondary" />
          </div>
        </div>
      </div>
    </section>
  )
}
