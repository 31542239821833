import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import HeroResources from '~/components/organisms/HeroResources';
import FeaturedResources from '~/components/organisms/FeaturedResources';
import Resources from '~/components/organisms/Resources';
import ResourcesSlider from '~/components/organisms/ResourcesSlider';
import Newsletter from '~/components/organisms/Newsletter';
import globalState from '~/store/index';
import CustomSEO from '~/components/seo/CustomSEO';

const ResourcesPage = (props) => {
  const { pageContext: { resourceType }, data: { wpPage: { title, resourcesListing: { resourcesListing } }, allWpPost, wp } } = props;

  const [type, setType] = globalState('resourceType');
  useEffect(() => {
    setType(resourceType);
  }, [resourceType])

  // OP-1375 => S47
  // State to store the modified post schema
  const [modifiedPostSchema, setModifiedPostSchema] = useState(null);

  // Modify the post schema to override the "name" field if it's empty
  useEffect(() => {
    if (wp.seo && wp.seo.contentTypes.post.schema.raw) {
      const schemaData = JSON.parse(wp.seo.contentTypes.post.schema.raw);

      // Access the last item in the "itemListElement" array
      const lastItem = schemaData['@graph'][2]['itemListElement'].slice(-1)[0];

      // Check if the "name" field is empty, then overwrite it with the title
      if (!lastItem.name) {
        lastItem.name = title;
        // Update the modified post schema
        setModifiedPostSchema(JSON.stringify(schemaData));
      }
    }
  }, [title, wp]);

  return (
    <Layout showResourcesNav headerBgWhite>
      <Seo
        title={`${title} | City Relay`}
        postSchema={modifiedPostSchema ? JSON.parse(modifiedPostSchema) : null}
      />
      <CustomSEO seo={wp?.seo} />
      <HeroResources heading={resourcesListing.heading} post={allWpPost.nodes[0]} />

      {resourcesListing.categories.map(({ type, header, category }, i) => {
        return type !== 'slider' ? (
          <FeaturedResources key={type + i} heading={header.heading} button={header.button} type={type} category={category} />
        ) : (
          <ResourcesSlider
            title={header.heading || category.name}
            button={[header.button]}
            category={category}
            posts={category.posts.nodes}
          />
        )
      })}
      <Newsletter heading="Sign up to our newsletter!" content="Subscribe to City Relay and be the first to hear about new content, upcoming events, our podcast, the latest industry news and much more." />
      <Resources resourceType={resourceType} />
    </Layout>
  );
};

export default ResourcesPage;


export const pageQuery = graphql`
  query ResourcesListingQuery($id: String!, $resourceType: String!) {

    allWpPost(
      limit: 1,
      sort: {fields: date, order: DESC},
      filter: {
        resourceTypes: { nodes: { elemMatch: { slug: { eq: $resourceType }}}},
        categories: { nodes: { elemMatch: { name: { nin: [ "Uncategorized", "Uncategorised"] }}}},
      }
    ) {
      nodes {
        ...PostPreview
      }
    }

    wpPage(id: { eq: $id }) {
      title
      resourcesListing {
        resourcesListing {
          heading
          categories {
            type
            header {
              heading
              button {
                ...Button
              }
            }
            category {
              name
              posts {
                nodes {
                  ...PostPreview
                }
              }
            }
          }
        }
      }
    }

    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
  }
`;
